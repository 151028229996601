import * as React from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import MainGrid from './components/MainGrid';
import SideMenu from './components/SideMenu';
import AppTheme from './shared-theme/AppTheme';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from './theme/customizations';
import DepositScreen from './components/DepositScreen';
import WithdrawalScreen from './components/WithdrawalScreen';
import Analytics from './components/Analytics';
import AssetScreen from './components/AssetScreen';
import Toast from './components/Toast';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Dashboard(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const lastPathRef = React.useRef(location.pathname);

  React.useEffect(() => {
    console.log(location.pathname);
    const pathsToReload = ['/app/deposit', '/app/withdraw', '/app/analytics', '/app/assets'];
    
    if (pathsToReload.includes(location.pathname) && location.pathname !== lastPathRef.current) {
      console.log("reloading");
      lastPathRef.current = location.pathname;
      window.location.reload();
    } else {
      console.log("not reloading");
      lastPathRef.current = location.pathname;
    }
  }, [location.pathname]);

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Routes key={location.pathname}>
              <Route index element={<MainGrid />} />
              <Route path="deposit" element={<DepositScreen />} />
              <Route path="withdraw" element={<WithdrawalScreen />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="assets" element={<AssetScreen />} />
              {/* Add routes for Settings, About, and Feedback if needed */}
            </Routes>
          </Stack>
        </Box>
      </Box>
      <Toast />
    </AppTheme>
  );
}
