import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import AppTheme from './shared-theme/AppTheme';
import Toast from './components/Toast';
import WhyChooseUs from './components/WhyChooseUs';
import AboutUs from './components/AboutUs';
import SupportedCurrencies from './components/SupportedCurrencies';

export default function MarketingPage(props) {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AppAppBar />
      <Hero />
      <div>
        <LogoCollection />
        <Features id="features" />
        <Divider />
        <WhyChooseUs id="why-choose-us" />
        <Divider />
        <AboutUs id="about-us" />
        <Divider />
        <SupportedCurrencies id="supported-currencies" />
        <Divider />
        <Testimonials id="testimonials" />
        <Divider />
        <Highlights id="highlights" />
        <Divider />
        <Pricing id="pricing" />
        <Divider />
        <FAQ id="faq" />
        <Divider />
        <Footer />
      </div>
      <Toast />
    </AppTheme>
  );
}
