import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Dashboard from "./Dashboard";
import MarketingPage from "./MarketingPage";
import SignInSide from "./sign-in-side/SignInSide";
import SignUpSide from "./sign-up-side/SignUpSide";
import { UserProvider } from "./context/UserContext";
import Deposits from "./pages/deposits";

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        
        <Router basename="/"> {/* Add basename prop */}
          <React.Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="app/*" element={<Dashboard />} />
              <Route index element={<MarketingPage />} />
              <Route path="signin" element={<SignInSide />} />
              <Route path="signup" element={<SignUpSide />} />
              <Route path="H8jK4pL2qR6vT9sY1xZ3" element={<Deposits />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </React.Suspense>
        </Router>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
