import { useState, useEffect, useCallback } from 'react';
import { getAllPendingDeposits, setDepositStatus } from '../utils/database';

export default function Deposits() {
  const [deposits, setDeposits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDeposits = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getAllPendingDeposits();
      setDeposits(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDeposits();
  }, [fetchDeposits]);

  const updateStatus = async (depositId, status) => {
    try {
      await setDepositStatus(depositId, status);
      await fetchDeposits(); // Refresh the list after update
    } catch (err) {
      setError(err.message);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Deposits</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {deposits.map((deposit) => (
            <tr key={deposit.id}>
              <td>{deposit.id}</td>
              <td>{deposit.amount}</td>
              <td>{deposit.status}</td>
              <td>
                {deposit.status === 'pending' && (
                  <div>
                    <button onClick={() => updateStatus(deposit.id, 'success')}>
                      Set Success
                    </button>
                    <button onClick={() => updateStatus(deposit.id, 'fail')}>
                      Set Failed
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
